.activity__img {
    display: flex;
    justify-content: center;
    height: 200px;
    background: #f5f5f5 !important;
}
.activity__name {
    font-size: 19px !important;
    font-weight: 600 !important;
}
.activity__place {
    margin-bottom: 8px !important;
    color: #808080;
    font-size: 15px !important;
    font-weight: 400 !important;
}
.activity__description-title {
    font-size: 19px !important;
    font-weight: 600 !important;
}
.activity__description-text {
    color: #808080;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
}

.activity__text {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.activity__gray-text {
    color: #808080;
    font-size: 15px !important;
    font-weight: 400 !important;
}
