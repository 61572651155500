.todo-application .content-area-wrapper {
    border: 1px solid #ebe9f1;
    border-radius: 0.428rem; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar {
    width: 260px;
    height: inherit;
    display: flex;
    background-color: #fff;
    border-top-left-radius: 0.428rem;
    border-bottom-left-radius: 0.428rem;
    transition: all 0.3s ease, background 0s; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu {
    width: 100%;
    z-index: 3; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .add-task {
    padding: 1.5rem; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .sidebar-menu-list {
    position: relative;
    height: calc(100% - 80px); }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item {
    padding: 0.58rem 1.5rem;
    border: 0;
    font-weight: 500;
    letter-spacing: 0.4px;
    border-left: 2px solid transparent;
    border-radius: 0; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item + .list-group-item.active {
    margin-top: 0; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item:hover, .todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item:focus, .todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item.active {
    background: transparent;
    color: #7367f0; }

.todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item.active {
    border-color: #7367f0; }

.todo-application .content-area-wrapper .ql-editor {
    padding-bottom: 0; }

.todo-application .content-area-wrapper .content-right {
    width: calc(100% - 260px);
    border-left: 1px solid #ebe9f1; }

.todo-application .content-area-wrapper .content-right .todo-app-list {
    height: inherit; }

.todo-application .content-area-wrapper .content-right .app-fixed-search {
    padding: 0.4215rem 0.5rem;
    border-bottom: 1px solid #ebe9f1;
    background-color: #fff;
    border-top-right-radius: 0.357rem; }

.todo-application .content-area-wrapper .content-right .app-fixed-search .input-group:focus-within {
    box-shadow: none; }

.todo-application .content-area-wrapper .content-right .app-fixed-search input,
.todo-application .content-area-wrapper .content-right .app-fixed-search .input-group-text {
    border: 0;
    background-color: transparent; }

.todo-application .content-area-wrapper .content-right .todo-title {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }

.todo-application .content-area-wrapper .content-right .completed .todo-title {
    color: #b9b9c3; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper {
    position: relative;
    height: calc(100% - 3.56rem);
    background-color: #fff;
    border-radius: 0; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list {
    padding: 0;
    margin: 0;
    list-style: none; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li {
    cursor: pointer;
    transition: all 0.2s, border-color 0s;
    position: relative;
    padding: 0.893rem 2rem; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li:not(:first-child) {
    border-top: 1px solid #ebe9f1; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li:hover {
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
    transition: all 0.2s; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .todo-title-wrapper {
    display: flex;
    justify-content: space-between; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .todo-title-area,
.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .title-wrapper {
    display: flex;
    align-items: center; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .todo-item-action {
    display: flex;
    align-items: center; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .todo-item-action > small {
    margin-left: auto; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .todo-item-action a {
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.5; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .badge-wrapper {
    display: flex; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .todo-task-list li .badge-wrapper .badge:not(:last-child) {
    margin-right: 0.5rem; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .no-results {
    display: none;
    padding: 1.5rem;
    text-align: center; }

.todo-application .content-area-wrapper .content-right .todo-task-list-wrapper .no-results.show {
    display: block; }

.todo-application .todo-item .drag-icon {
    visibility: hidden;
    cursor: move;
    position: absolute;
    left: 0.2rem;
    width: 1.75rem;
    height: 4rem;
    padding: 0 5px; }

.todo-application .todo-item:hover .drag-icon {
    visibility: visible; }

.todo-application form .error:not(li):not(input) {
    color: #ea5455;
    font-size: 85%;
    margin-top: 0.25rem; }

.gu-mirror {
    list-style-type: none;
    list-style: none;
    padding: 0.893rem 2rem;
    background-color: #fff;
    border-top: 1px solid #ebe9f1;
    border-bottom: 1px solid #ebe9f1;
    box-shadow: 0 0 10px 0 rgba(34, 41, 47, 0.25); }

.gu-mirror .todo-title-wrapper {
    display: flex;
    justify-content: space-between; }

.gu-mirror .todo-title-area,
.gu-mirror .title-wrapper {
    display: flex;
    align-items: center; }

.gu-mirror .todo-item-action {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.gu-mirror .todo-title {
    padding-left: 1rem; }

.gu-mirror.completed .todo-title {
    color: #b9b9c3; }

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        top: 100px; }
    75% {
        opacity: 0.5;
        top: 0px; }
    100% {
        opacity: 1; } }

@media (max-width: 991.98px) {
    .todo-application .content-area-wrapper .sidebar-left .todo-sidebar {
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        left: 0;
        position: absolute;
        z-index: 5;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem; }
    .todo-application .content-area-wrapper .sidebar-left.show .todo-sidebar {
        transform: translateX(0%);
        transition: all 0.3s ease; }
    .todo-application .content-area-wrapper .content-right {
        width: 100%;
        border-left: 0; }
    .todo-application .content-area-wrapper .content-right .app-fixed-search {
        border-top-left-radius: 0.357rem; }
    .todo-application .content-area-wrapper .todo-title-wrapper {
        flex-direction: column; }
    .todo-application .content-area-wrapper .todo-title-wrapper .title-wrapper {
        margin-bottom: 0.5rem; }
    .todo-application .content-area-wrapper .todo-title-wrapper .todo-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden; }
    .todo-application .content-area-wrapper .todo-title-wrapper .badge-wrapper {
        margin-right: auto !important; } }

@media (max-width: 349.98px) {
    .todo-application .content-area-wrapper .sidebar .todo-sidebar {
        width: 230px; } }

.horizontal-menu .todo-application .content-area-wrapper .content-right .todo-task-list-wrapper {
    height: calc(100% - 3.6rem); }
